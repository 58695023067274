<template>
  <div>
    <Assignee
      :mode="mode"
      :task="task"
      :noArrow="noArrow"
      :fitContent="fitContent"
      :disabled="!hasEditPermission"
      @click:member="clickMember"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Assignee from "@/flow/views/components/commons/task/assignee";

export default {
  components: { Assignee },
  props: {
    mode: {
      type: String,
      default: ""
    },
    task: {
      type: Object,
      default: () => ({})
    },
    noArrow: {
      type: Boolean,
      default: false
    },
    fitContent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("flowTasks", ["hasEditPermission"])
  },
  methods: {
    ...mapActions("flowTasks", ["updateAssignee"]),
    clickMember(member) {
      const { userId } = member;
      const { id: taskId } = this.task;
      this.updateAssignee({ taskId, userId });
    }
  }
};
</script>
